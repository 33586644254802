import {
	ICampaign,
	ICampaigns,
	ICampaignsCalendar,
	ICreativeRegeneratePayload,
} from 'src/lib/schemas';
import http from './http';
import { processCallback } from 'src/lib/utils/processCallback';

const apiVersion = 'v2';
const campaignUrl = `/${apiVersion}/campaign`;

export const createOrUpdateCampaign = async (
	payload: Partial<Omit<ICampaign, 'id'>>,
	id: string,
): Promise<ICampaign> => {
	const shouldIncludeTemplateId =
		sessionStorage.getItem('templateSelected') === 'true';

	const payloadToSend = shouldIncludeTemplateId
		? payload
		: { ...payload, templateId: undefined };

	if (id && id !== 'new') {
		const { data } = await http.patch<ICampaign>(
			`${campaignUrl}/${id}`,
			payloadToSend,
		);
		return data;
	}

	const { data } = await http.post<ICampaign>(campaignUrl, payloadToSend);
	return data;
};
export const launchCampaign = async (id: string) => {
	const response = await http.post(`${campaignUrl}/launch/${id}`);
	return response.data;
};
export const publishCampaignChannels = async (
	campaignId: string,
	placements: string[],
) => {
	const body = {
		inputs: {
			campaignId,
			placements,
		},
	};

	const { data } = await http.post(
		'/v2/apps/fusion_ai.publish_content/execute/publish_channels',
		body,
	);

	return data;
};

export const publishCampaignChannelsAndWait = async (
	campaignId: string,
	placements: string[],
): Promise<any> => {
	try {
		const publishResponse = await publishCampaignChannels(
			campaignId,
			placements,
		);
		const finalResponse = await processCallback(publishResponse.callback);
		return finalResponse;
	} catch (error) {
		return null;
	}
};
export const getCampaigns = async (query: string) => {
	const { data } = await http.get<ICampaigns>(`${campaignUrl}?${query}`);
	return data;
};

export const getCampaign = async (id: string) => {
	const { data } = await http.get<ICampaign>(`${campaignUrl}/${id}`);
	return data;
};

export const archiveCampaign = async (id: string) => {
	const { data } = await http.patch<ICampaign>(`${campaignUrl}/${id}`, {
		status: 'archived',
	});
	return data;
};

export const duplicateCampaign = async (id: string): Promise<ICampaign> => {
	const { data } = await http.post<ICampaign>(`${campaignUrl}/${id}/duplicate`);
	return data;
};

export const executeCampaignCreatives = async (
	campaignId: string,
	backgroundPrompt?: string,
) => {
	const payload: { backgroundPrompt?: string } = {};
	if (backgroundPrompt) {
		payload.backgroundPrompt = backgroundPrompt;
	}
	await http.post(`${campaignUrl}/execute/${campaignId}`, payload);
};

export const genrateCampaignCreatives = async (
	campaignId: string,
	designDirectionId?: string,
	onlyUnprocessed: boolean = false,
) => {
	const id = designDirectionId ? `/${designDirectionId}` : '';
	await http.post(
		`${campaignUrl}/${campaignId}/generate-creatives${id}?onlyUnprocessed=${onlyUnprocessed}`,
	);
};

export const genrateCampaignDesignDirections = async (
	campaignId: string,
	templateId?: string,
) => {
	if (templateId) {
		await http.post(`${campaignUrl}/${campaignId}/design-direction`, {
			templateId,
		});
	} else {
		await http.post(`${campaignUrl}/${campaignId}/design-direction`);
	}
};

export const editCampaignDesignDirections = async (
	campaignId: string,
	designId: string,
	payload: any,
) => {
	await http.patch(
		`${campaignUrl}/${campaignId}/design-direction/${designId}`,
		payload,
	);
};
export const generateDesignDirectionWithTemplate = async (
	campaignId: string,
	templateId: string,
) => {
	try {
		await http.post(`${campaignUrl}/${campaignId}/add-design-direction/1`, {
			templateId,
		});
	} catch (error: any) {
		throw new Error(`Failed to generate design direction: ${error.message}`);
	}
};

export const generateDesignDirectionsWithTemplates = async (
	campaignId: string,
	templateIds: string[],
) => {
	try {
		const numTemplates = templateIds.length;
		await http.post(
			`${campaignUrl}/${campaignId}/add-design-direction/${numTemplates}`,
			{
				templateIds,
			},
		);
	} catch (error: any) {
		throw new Error(`Failed to generate design directions: ${error.message}`);
	}
};

export const genrateNewDesignDirection = async (campaignId: string) => {
	await http.post(`${campaignUrl}/${campaignId}/add-design-direction/1`);
};
export const removeDesignDirection = async (
	campaignId: string,
	designDirectionId: string,
) => {
	await http.delete(
		`${campaignUrl}/${campaignId}/design-direction/${designDirectionId}`,
	);
};
export const genrateCampaignDesignDirectionsWithTemplate = async (
	campaignId: string,
	templateId?: string,
) => {
	if (templateId) {
		await http.post(`${campaignUrl}/${campaignId}/design-direction`, {
			templateId,
		});
	} else {
		await http.post(`${campaignUrl}/${campaignId}/design-direction`);
	}
};
export const regenerateCampaignCreative = async (
	campaignId: string,
	payload: ICreativeRegeneratePayload,
) => {
	const { data } = await http.patch<ICampaign>(
		`${campaignUrl}/regenerate/${campaignId}/${payload.creativeId}`,
		payload,
	);
	return data;
};

export const regenerateCreativesVariant = async (
	id: string,
	variant: 'A' | 'B',
	payload: Partial<ICreativeRegeneratePayload>,
) => {
	const { data } = await http.patch<ICampaign>(
		`${campaignUrl}/regenerate/${id}/${variant}`,
		payload,
	);
	return data;
};

export const regenerateCampaignDesignDirection = async (
	campaignId: string,
	designDirectionId: string,
	payload: any,
): Promise<ICampaign> => {
	const { data } = await http.patch<ICampaign>(
		`${campaignUrl}/regenerate/${campaignId}/design-direction/${designDirectionId}`,
		payload,
	);
	return data;
};

export const getCampaignCalendar = async (query?: Record<string, string>) => {
	const { data } = await http.get<ICampaignsCalendar>(
		`${campaignUrl}/calendar?${new URLSearchParams(query).toString()}`,
	);
	return data;
};

export const updateCampaignCreative = async (
	id: string,
	creativeId: string,
	payload: any,
) => {
	const { data } = await http.patch<ICampaign>(
		`${campaignUrl}/${id}/update-creative/${creativeId}`,
		payload,
	);

	return data;
};
