import { FC, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import CampaignContext from 'src/contexts/campaign/CampaignContext';
import { getCampaign } from 'src/services/campaign';
import { cleanObject_id } from 'src/lib/utils';
import { toastError } from 'src/services/toast';
import { ICampaign } from 'src/lib/schemas';

interface CampaignContextProps {
	children: React.ReactNode;
}
interface Step {
	label: string;
	isCompleted: boolean;
	isActive: boolean;
}
const initialSteps: Step[] = [
	{ label: 'Gather Information', isCompleted: false, isActive: true },
	{ label: 'Generate Designs', isCompleted: false, isActive: false },
	{ label: 'Preview & Finalize', isCompleted: false, isActive: false },
	{ label: 'Schedule & Publish', isCompleted: false, isActive: false },
];
const CampaignProvider: FC<CampaignContextProps> = ({ children }) => {
	const [isFetching, setIsFetching] = useState<boolean>(true);
	const [campaign, setCampaign] = useState<ICampaign | null>(null);
	const { campaignId } = useParams<{ campaignId: string }>();
	const [isLaunching, setIsLaunching] = useState<boolean>(false);
	const [oldCampaignCopy, setOldCampaignCopy] = useState<ICampaign | null>(
		null,
	);

	const [steps, setSteps] = useState<Step[]>(initialSteps);
	const [enabledSections, setEnabledSections] = useState<boolean[]>([
		true,
		false,
		false,
	]);
	const hasFinishedLaunchRef = useRef<boolean>(false);
	const isLaunchingRef = useRef(false);
	const sectionsInitialValue = campaign?.designDirections
		? [false, false, false, false]
		: [true, false, false, false];
	const [openSections, setOpenSections] = useState(sectionsInitialValue);

	const fetchCampaign = async () => {
		if (!campaignId || campaignId === 'new') {
			resetStepsAndSections();
			setIsFetching(false);
			return;
		}
		try {
			const response = await getCampaign(campaignId);
			const cleanedCampaign = cleanObject_id(response) as ICampaign;
			setCampaign(cleanedCampaign);
			updateStepsAndSections(cleanedCampaign);
		} catch (error: any) {
			toastError(error);
		}
		setIsFetching(false);
	};
	const resetStepsAndSections = () => {
		setSteps(initialSteps);
		setEnabledSections([true, false, false]);
	};
	const deepCopySteps = (steps: Step[]): Step[] => {
		return steps.map((step) => ({ ...step }));
	};

	const updateStepsAndSections = (campaign: ICampaign) => {
		const hasDesignDirections = campaign.designDirections?.length;
		const hasScheduledDate = campaign.schedule?.startDate;
		const newSteps = deepCopySteps(initialSteps);

		const newEnabledSections = [true, false, false, false];
		if (hasDesignDirections) {
			newSteps[0].isCompleted = true;
			newSteps[1].isActive = true;
			newEnabledSections[1] = true;
		}
		if (hasScheduledDate) {
			newSteps[1].isCompleted = true;
			newSteps[2].isActive = true;
			newEnabledSections[2] = true;
		}
		if (campaign.status === 'published') {
			newSteps[2].isCompleted = true;
			newSteps[3].isActive = true;
		}
		setSteps(newSteps);
		setEnabledSections(newEnabledSections);
		localStorage.setItem(
			`enabledSections-${campaignId}`,
			JSON.stringify(newEnabledSections),
		);
	};
	const completeStep = (stepIndex: number) => {
		setSteps((prevSteps) => {
			const updatedSteps = [...prevSteps];
			if (updatedSteps[stepIndex]) {
				updatedSteps[stepIndex].isCompleted = true;
				if (updatedSteps[stepIndex + 1]) {
					updatedSteps[stepIndex + 1].isActive = true;
				}
			}
			return updatedSteps;
		});
		setEnabledSections((prevSections) => {
			const updatedSections = [...prevSections];
			if (updatedSections[stepIndex + 1] === false) {
				updatedSections[stepIndex + 1] = true;
			}
			localStorage.setItem(
				`enabledSections-${campaignId}`,
				JSON.stringify(updatedSections),
			);
			return updatedSections;
		});
	};
	useEffect(() => {
		setSteps(
			initialSteps.map((step) => ({
				...step,
				isCompleted: false,
				isActive: false,
			})),
		);
		fetchCampaign();
	}, [campaignId]);

	return (
		<CampaignContext.Provider
			value={{
				isFetching,
				id: campaignId,
				campaign,
				setCampaign,
				isLaunching,
				setIsLaunching,
				steps,
				completeStep,
				enabledSections,
				openSections,
				setOpenSections,
				oldCampaignCopy,
				setOldCampaignCopy,
				hasFinishedLaunchRef,
				isLaunchingRef,
			}}
		>
			{children}
		</CampaignContext.Provider>
	);
};
export default CampaignProvider;
