import { useEffect, useRef, useState } from 'react';
import { Box, Center, Divider, Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import FusionLoading from 'src/components/common/FusionLoading';
import {
	AppActions,
	AppHeader,
	AppInputs,
	AppOutputHeader,
	AppOutputs,
	AppShare,
} from 'src/components/app';
import { getApp } from 'src/services/app';
import http from 'src/services/http';
import { cleanInputObject } from 'src/components/app/utils/misc';
import { customToast, toastError } from 'src/services/toast';
import useFetchWithDependencies from 'src/hooks/useFetchWithDependencies';

const apiVersion = 'v2';
const appsUrl = `/${apiVersion}/apps`;

const App = () => {
	const [actionId, setActionId] = useState('');
	const [toggleView, setToggleView] = useState('preview');
	const [outputData, setOutputData] = useState<any>(null);
	const topElementRef = useRef<HTMLDivElement>(null);
	const [responseStatus, setResponseStatus] = useState('idle');

	const { appId, paramActionId } = useParams();

	const { data: appConfig, isLoading } = useFetchWithDependencies(
		() => getApp({ id: appId ?? '' }),

		[appId],
	);


	const filteredActions = appConfig?.actions?.filter((action) => !action.hidden) || [];

	const isSingleAction = filteredActions.length === 1;

	useEffect(() => {
		let selectAction = null;
		if (isSingleAction) {
			selectAction = filteredActions[0].id;
		} else if (paramActionId) {
			selectAction = paramActionId;
		}
		if (!isLoading && selectAction !== null) {
			setActionId(selectAction);
		}
	}, [appConfig, isLoading, paramActionId, actionId]);

	const handleActionChange = async (actionId: string) => {
		setActionId(actionId);
		window.history.replaceState(
			null,
			appConfig?.name ?? '',
			`/tasks/${appId}/${actionId}`,
		);
		setOutputData(undefined);
	};

	const handleInputSubmit = async (inputs: any) => {
		scrollTo({ top: 0, behavior: 'smooth' });
		setResponseStatus('pending');
		setOutputData(undefined);
		const cleanedInputs = cleanInputObject(inputs);

		try {
			const { data } = await http.post(
				`${appsUrl}/${appConfig?.fullName}/execute/${actionId}`,
				{ inputs: cleanedInputs },
			);
			processCallback(data.callback);
		} catch (e) {
			setResponseStatus('idle');
			setOutputData(undefined);
			toastError(e);
		}
	};

	const processCallback = async (callbackUrl: string) => {
		try {
			const { data } = await http.get(callbackUrl);
			const { status } = data;
			setResponseStatus(status);
			setOutputData(data.body);
			if (['processing', 'pending', 'partialContent'].includes(status)) {
				setResponseStatus(status === 'partialContent' ? 'processing' : status);
				setTimeout(processCallback.bind(null, callbackUrl), 500);
			} else if (status === 'error' || status === 'failed') {
				console.error(data);
				customToast(JSON.stringify(data), 'error');
			}
		} catch (e) {
			toastError(e);
		}
	};

	const appActionOptions =
		filteredActions.map((action) => ({
			label: action.name,
			value: action.id,
		})) || [];

	const actionSelected = appConfig?.actions?.find(
		(action) => action.id === actionId,
	);
	const filteredInputs =
		actionSelected?.inputs?.filter((input) => !input.hidden) || [];

	const filteredOutputs =
		actionSelected?.outputs?.filter((output) => !output.hidden) || [];


	const handleToggleView = (value: string) => {
		setToggleView(value);
	};

	const isPendingOutput = ['pending', 'processing'].includes(responseStatus);
	const isProcessingOutput = responseStatus === 'processing';

	return (
		<Box ref={topElementRef} display="flex" w="full" justifyContent="center">
			<FusionLoading isLoading={isLoading} />
			{!isLoading && appConfig && (
				<Flex gap={10} pb={10} w="full" maxW="80%" justifyContent="center">
					<Flex
						width="50%"
						flex={1}
						direction="column"
						minH="400px"
						gap={4}
						py={4}
						pb={8}
						px={6}
						borderRadius="8px"
						boxShadow="0 0px 15px -3px rgba(0, 0, 0, 0.1)"
						position="relative"
						zIndex={0}
					>
						<Flex justifyContent="space-between">
							<AppHeader logo={appConfig.logo} name={appConfig.name} />
							<AppShare />
						</Flex>
						{!isSingleAction && (
							<AppActions
								options={appActionOptions}
								onActionChange={handleActionChange}
								selectedValue={actionId}
							/>
						)}
						{actionSelected && !isSingleAction && <Divider />}
						{actionSelected && (
							<AppInputs
								inputs={filteredInputs}
								onSubmit={handleInputSubmit}
								isGeneratingOutput={isPendingOutput || isProcessingOutput}
							/>
						)}
					</Flex>

					<Flex
						width="50%"
						flex={1}
						direction="column"
						minW="300px"
						minH="400px"
						gap={4}
						py={4}
						pb={12}
						px={6}
						borderRadius="8px"
						boxShadow="0 0px 15px -3px rgba(0, 0, 0, 0.1)"
						bg={outputData ? 'white' : '#EDEEEF'}
						cursor={outputData ? '' : 'not-allowed'}
					>
						<FusionLoading isLoading={isPendingOutput} />
						<Box
							visibility={isPendingOutput || !outputData ? 'hidden' : 'visible'}
						>
							<AppOutputHeader
								toggleView={toggleView}
								onToggleView={handleToggleView}
							/>
							{actionSelected && (
								<AppOutputs
									outputSkeleton={filteredOutputs}
									result={outputData}
									isProcessing={isProcessingOutput}
									view={toggleView}
								/>
							)}
						</Box>
					</Flex>
				</Flex>
			)}
		</Box>
	);
};

export default App;
