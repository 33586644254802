import {
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Box,
	Button,
	Container,
	Flex,
	HStack,
	Heading,
	Text,
	Image,
	Spacer,
} from '@chakra-ui/react';
import {
	CHANNELS,
	defaultCampaign,
} from 'src/lib/schemas/campaign/defaultCampaign';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	MultiSelectCreateableInputHook,
	MultiSelectValuesHook,
	PromoCodesInputHook,
	StringInputHook,
	TextareaInputHook,
} from '../common/form';
import { ProductFormModal } from '../commerce/products';
import ConfigFormModal from '../config/ConfigFormModal';
import { AppInputConfigContext, CampaignContext } from 'src/contexts';
import useToggleWithPayload from 'src/hooks/useToggleWithPayload';
import useFetch from 'src/hooks/useFetch';
import useAccountConfigOptions from 'src/hooks/config/useAccountConfigOptions';
import useCatalogKinds from 'src/hooks/useCatalogKinds';
import {
	createAccountConfigOptions,
	getAccountConfigOptions,
	updateAccountConfigOptions,
	updateCompanyAccount,
} from 'src/services/account';
import {
	ECampaignPromotedType,
	ICampaign,
	ICatalog,
	ICatalogForm,
	IChannel,
	IChannelGroup,
	IDesignDirection,
} from 'src/lib/schemas';
import { IAccountConfigOption, IOption } from 'src/lib/schemas/misc';
import { transformCampaignToForm } from 'src/lib/utils';
import { toastError, toastSuccess } from 'src/services/toast';
import { ChannelType } from './channels/ChannelType';
import MarketingChannelsForm from './channels/MarketingChannelsForm';
import {
	ICampaignForm,
	CampaignFormSchema,
} from 'src/lib/schemas/campaign/form';
import useAutoSaveCampaign from 'src/hooks/v2/useAutoSaveCampaign';
import DesignLockModal from './design-directions/LockModal';
import {
	createOrUpdateCampaign,
	genrateCampaignCreatives,
	getCampaign,
} from 'src/services/campaign';
import UserContext from 'src/contexts/UserContext';
import { debounce } from 'lodash';
import MultiSelectCreatProducteableInputHook from '../common/form/MultiSelectCreateableInput/MultiSelectCreateableProductInputHook';
import SelectTemplateModal from './design-directions/SelectTemplateModal';
import TemplateContext from 'src/contexts/templates/TemplatesContext';
import SelectedTemplatePreview from './SelectTemplatePreview';
import { useDebouncedFunction } from 'src/hooks/v2/useDebouncedFunction';
import CustomContainer from '../common/Container';
import DomainForm from 'src/pages/scraping/DomainForm';
import { IScrapeDataOutput } from 'src/lib/schemas/scrapping';
import { AccountSettingsModal } from '../account';
import EditIcon2 from 'src/assets/icons/EditIcon2';
import { IMAGE_PLACEHOLDER } from './utils/constants';
import { ICreative } from 'src/lib/schemas/campaign/newFlowCampaign';
import { IIndustry } from 'src/services/industries';
import { IAccountSettingsForm } from 'src/lib/schemas/account/settings';
import { processCallback } from 'src/lib/utils/processCallback';
import { CheckIcon } from '@chakra-ui/icons';
import { ICampaignInterpretations } from 'src/lib/schemas/campaign';

interface CampaignFormProps {
	group?: string;
	availableChannels: IChannel[];
	onCampaignTitleChange: (title: string) => void;
	onCampaignSubmit: () => void;
	onTemplateGalleryOpen: () => void;
	onMetaOptionsChange: (options: IOption[]) => void;
	availableChannelGroups: IChannelGroup[] | null;
	setOpenSections: React.Dispatch<React.SetStateAction<boolean[]>>;
	designDirectionsRef: React.RefObject<HTMLDivElement>;
	selectedPlacements: string[];
	setSelectedPlacements: React.Dispatch<React.SetStateAction<string[]>>;
	designDirections: IDesignDirection[];
	setDesignDirections: React.Dispatch<React.SetStateAction<IDesignDirection[]>>;
	setCreatives: React.Dispatch<React.SetStateAction<ICreative[]>>;
	onRegenerateDesignDirections: () => void;
	onChannelsChange: React.Dispatch<React.SetStateAction<string[]>>;
	setScrappedDataLoading: React.Dispatch<React.SetStateAction<boolean>>;
	scrappedDataLoading: boolean;
}

const CampaignForm: FC<CampaignFormProps> = ({
	group,
	availableChannels,
	onCampaignTitleChange,
	onCampaignSubmit,
	onTemplateGalleryOpen,
	onMetaOptionsChange,
	designDirectionsRef,
	availableChannelGroups,
	selectedPlacements,
	setSelectedPlacements,
	designDirections,
	setDesignDirections,
	setCreatives,
	onRegenerateDesignDirections,
	onChannelsChange,
	setScrappedDataLoading,
	scrappedDataLoading,
}) => {
	const formProductToggle = useToggleWithPayload<Partial<ICatalog>>();
	const formToneToggle = useToggleWithPayload<IAccountConfigOption>();
	const formAudienceToggle = useToggleWithPayload<IAccountConfigOption>();
	const lockDDToggle = useToggleWithPayload<IDesignDirection[]>([]);
	const [dirtyPlacements, setDirtyPlacements] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isAccountSettingsModalOpen, setIsAccountSettingsModalOpen] =
		useState(false);
	const [isCampaignLoaded, setIsCampaignLoaded] = useState(false);
	const [isChannelUpdating, setIsChannelUpdating] = useState(false);
	const [isPlacementUpdating, setIsPlacementUpdating] = useState(false);
	const [isTonesAndAudienceLoading, setIsTonesAndAudienceLoading] =
		useState(false);
	const { inputConfig, loadingInputs, loadedInputs, setLoadingInputConfig } =
		useContext(AppInputConfigContext);
	const { fetchConfig: fetchTags } = useAccountConfigOptions('Tag');
	const [isGenerating, setIsGenerating] = useState(false);
	const [isFilled, setIsFilled] = useState(false);
	const {
		selectedTemplateIds,
		setSelectedTemplateIds,
		setSelectedTemplate,
		setSelectTemplateModalOpen,
		templates,
		templatesByScope,
		isSelectTemplateModalOpen,
	} = useContext(TemplateContext);
	const { account, setAccount, pendingAccountSettings } =
		useContext(UserContext);
	const { fetchConfig: fetchTones, createConfig: createTone } =
		useAccountConfigOptions('Tone');
	const { fetchConfig: fetchAudiences, createConfig: createAudience } =
		useAccountConfigOptions('Audience');

	const { catalogKinds } = useCatalogKinds();
	const [isPromotedObjectLoaded, setIsPromotedObjectLoaded] = useState(false);
	const [scrapeDataCalled, setScrapeDataCalled] = useState(false);
	const [scrappedCatalogsLoading, setScrappedCatalogsLoading] = useState(false);
	const [scrappedCatalogs, setScrappedCatalogs] = useState<any[]>([]);
	const [accountSettingsFields, setAccountSettingsFields] =
		useState<Partial<IAccountSettingsForm>>();
	const promotedObjectRef = useRef<HTMLDivElement>(null);
	const {
		campaign,
		id: campaignId,
		setCampaign,
		completeStep,
		setOpenSections,
	} = useContext(CampaignContext);
	const navigate = useNavigate();
	const location = useLocation();
	const locationState = location.state;
	const [searchParams] = useSearchParams();
	const sectionEndRef = useRef<HTMLDivElement>(null);
	const isNewCampaign = campaignId && campaignId === 'new';

	const formMethods = useForm<ICampaignForm>({
		resolver: zodResolver(CampaignFormSchema),
		defaultValues: defaultCampaign as unknown as ICampaignForm,
	});
	const { reset, setValue } = formMethods;
	const isFormDirty = formMethods.formState.isDirty;
	const watchedTitle = formMethods.watch('title');
	const watchedDescription = formMethods.watch('description');
	const promotedObjectType = formMethods.watch('promotedObject.type');
	const destination = formMethods.watch('destination');
	const watchedChannels = formMethods.watch('channels') ?? {};
	const watchedPlacements = formMethods.watch('placements') ?? [];
	const watchedPromotedObjectValue = formMethods.watch('promotedObject.value');
	const watchedDestination = formMethods.watch('destination');
	const watchedGoals = formMethods.watch('goal') ?? [];
	const watchedAudience = formMethods.watch('audience');
	const watchedTones = formMethods.watch('tone');
	const watchedPromotion = formMethods.watch('promotion');
	const [pendingInterpretations, setPendingInterpretations] = useState<{
		url_industry?: any;
		url_source?: string;
		url_keywords?: string[];
	} | null>(null);
	const [scrapedIndustry, setScrapedIndustry] = useState<IIndustry | null>(
		null,
	);
	const [scrapedKeywords, setScrapedKeywords] = useState<string[] | null>(null);
	const [tempUrlSource, setTempUrlSource] = useState<string | null>(null);

	const updateCampaignChannels = useCallback(async () => {
		if (!locationState?.selectedChannel) return;

		let selectedChannels = [];
		switch (locationState.selectedChannel) {
			case 'all':
				selectedChannels = CHANNELS;
				break;
			case 'paid':
				selectedChannels = ['facebookPaid', 'instagramPaid', 'google'];
				break;
			case 'direct':
				selectedChannels = ['sms', 'pns', 'email'];
				break;
			case 'facebook':
				selectedChannels = ['facebook', 'facebookPaid'];
				break;
			case 'instagram':
				selectedChannels = ['instagram', 'instagramPaid'];
				break;
			default:
				selectedChannels = [locationState.selectedChannel];
		}

		try {
			const newCampaign = await createOrUpdateCampaign(
				{ channels: selectedChannels },
				'new',
			);
			navigate(`/projects/campaigns/${newCampaign.id}`, {
				replace: true,
				state: { selectedChannels },
			});
		} catch (error) {
			console.error(error);
		}
	}, [locationState?.selectedChannel, navigate]);

	useEffect(() => {
		if (!campaignId || !locationState) {
			return;
		}

		if (locationState.selectedChannel) {
			updateCampaignChannels();
		}

		if (!loadedInputs) {
			return;
		}

		if (locationState.catalogCallback) {
			setScrappedCatalogsLoading(true);
			handleScrappedCatalogs(locationState.catalogCallback);
		}

		if (locationState.tonesAndAudienceCallback) {
			setIsTonesAndAudienceLoading(true);
			handleScrappedTonesAndAudiences(locationState.tonesAndAudienceCallback);
		}
	}, [campaignId, locationState, updateCampaignChannels, loadedInputs]);

	const handleCancel = () => {
		setOpenSections([false, true, false, false]);
		if (designDirectionsRef.current) {
			const elementPosition =
				designDirectionsRef.current.getBoundingClientRect().top +
				window.scrollY;
			const offsetPosition = elementPosition - 400;
			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth',
			});
		}
	};

	const autosave = useAutoSaveCampaign(
		setDirtyPlacements,
		setDesignDirections,
		setCreatives,
	);

	useEffect(() => {
		if (!campaign?.id) {
			autosave.onFieldsChange(undefined);
		}
	}, [campaign?.id]);

	useEffect(() => {
		if (!formMethods.watch('channels') && campaign?.channels) {
			setValue('channels', campaign.channels, { shouldDirty: false });
		}
	}, [campaign?.channels, setValue]);

	useEffect(() => {
		if (isNewCampaign && availableChannels) {
			reset(defaultCampaign as unknown as ICampaignForm);
			setValue('channels', CHANNELS);

			const selectedPlacementIds = availableChannels.flatMap(
				(channel) => channel.placements?.map((placement) => placement.id) || [],
			);
			setValue('placements', selectedPlacementIds, { shouldDirty: false });
			setValue('promotedObject.type', ECampaignPromotedType.landingPage, {
				shouldDirty: false,
			});
		}
	}, [campaignId, availableChannels, reset, setValue]);

	useEffect(() => {
		if (watchedChannels) {
			onChannelsChange(watchedChannels);
		}
	}, [watchedChannels]);

	const selectedTemplate = useMemo(() => {
		if (selectedTemplateIds?.length === 0) return null;
		const id = selectedTemplateIds[0];
		return (
			templates.find((t) => t.id === id) ||
			templatesByScope.find((t) => t.id === id)
		);
	}, [selectedTemplateIds, templates, templatesByScope]);

	useEffect(() => {
		onCampaignTitleChange(watchedTitle);
	}, [watchedTitle]);

	useEffect(() => {
		if (loadingInputs['Tone'] || loadedInputs['Tone']) return;
		fetchTones();
	}, [loadingInputs['Tone'], loadedInputs['Tone']]);

	useEffect(() => {
		if (loadingInputs['Audience'] || loadedInputs['Audience']) return;
		fetchAudiences();
	}, [loadingInputs['Audience'], loadedInputs['Audience']]);

	useEffect(() => {
		if (watchedTitle) {
			formMethods.clearErrors('title');
		}
		if (watchedDescription) {
			formMethods.clearErrors('description');
		}
		if (watchedAudience?.length) {
			formMethods.clearErrors('audience');
		}
		if (watchedTones?.length) {
			formMethods.clearErrors('tone');
		}
		if (
			watchedPromotedObjectValue?.length ||
			promotedObjectType === ECampaignPromotedType.landingPage
		) {
			formMethods.clearErrors('promotedObject.value');
		}
		if (destination) {
			formMethods.clearErrors('destination');
		}
		if (watchedPlacements?.length > 0) {
			formMethods.clearErrors('placements');
			formMethods.clearErrors('channels');
		}
	}, [
		watchedTitle,
		watchedDescription,
		watchedAudience,
		watchedTones,
		destination,
		watchedPromotedObjectValue,
		promotedObjectType,
		watchedPlacements?.length,
	]);

	const debouncedCreateOrUpdateCampaign = useDebouncedFunction(
		createOrUpdateCampaign,
		300,
	);

	const handleChannelChange = (channelId: string, channelType: ChannelType) => {
		setDirtyPlacements(true);
		if (isChannelUpdating) return;
		setIsChannelUpdating(true);

		const selectedChannel = availableChannels.find(
			(channel) => channel.id === channelId,
		);

		if (!selectedChannel || !selectedChannel.placements) {
			setIsChannelUpdating(false);
			return;
		}

		const selectedPlacementIds = selectedChannel.placements
			.filter((placement) => placement.type === channelType)
			.map((item) => item.id);

		setSelectedPlacements((prevSelectedPlacements) => {
			const allSelected = selectedPlacementIds.every((id) =>
				prevSelectedPlacements.includes(id),
			);

			const updatedPlacements = allSelected
				? prevSelectedPlacements.filter(
						(id) => !selectedPlacementIds.includes(id),
				  )
				: Array.from(
						new Set([...prevSelectedPlacements, ...selectedPlacementIds]),
				  );

			setValue('placements', updatedPlacements, { shouldDirty: true });

			onChannelsChange((prevChannels) => {
				const updatedChannels = allSelected
					? prevChannels.filter((id) => id !== channelId)
					: Array.from(new Set([...prevChannels, channelId]));
				if (updatedChannels.length > 0 && updatedPlacements.length > 0) {
					const debouncedResult = debouncedCreateOrUpdateCampaign(
						{
							channels: updatedChannels,
						},
						campaignId ?? 'new',
					);
					if (debouncedResult) {
						debouncedResult.finally(() => setDirtyPlacements(false));
					} else {
						setDirtyPlacements(false);
					}
				} else {
					setDirtyPlacements(false);
				}
				setValue('channels', updatedChannels);

				return updatedChannels;
			});

			setIsChannelUpdating(false);
			return updatedPlacements;
		});
	};

	const handlePlacementChange = (placementId: string) => {
		if (isPlacementUpdating) return;
		setIsPlacementUpdating(true);

		setSelectedPlacements((prevSelectedPlacements) => {
			const updatedPlacements = prevSelectedPlacements.includes(placementId)
				? prevSelectedPlacements.filter((id) => id !== placementId)
				: [...prevSelectedPlacements, placementId];

			setValue('placements', updatedPlacements);

			const updatedChannels = availableChannels
				.filter(
					(channel) =>
						channel.placements?.some((placement) =>
							updatedPlacements.includes(placement.id),
						),
				)
				.map((channel) => channel.id);

			if (updatedChannels.length > 0 && updatedPlacements.length > 0) {
				const debouncedResult = debouncedCreateOrUpdateCampaign(
					{
						placements: updatedPlacements,
					},
					campaignId ?? 'new',
				);

				if (debouncedResult) {
					debouncedResult.finally(() => setDirtyPlacements(false));
				} else {
					setDirtyPlacements(false);
				}
			} else {
				setDirtyPlacements(false);
			}
			setValue('channels', updatedChannels);

			setIsPlacementUpdating(false);
			return updatedPlacements;
		});
	};

	const handleRefreshDesignDirections = async () => {
		if (!campaignId) return;
		try {
			const response = await getCampaign(campaignId);
			setDesignDirections(response.designDirections ?? []);
			setCampaign(response);
		} catch (error: any) {
			toastError(error);
		}
	};

	useEffect(() => {
		if (account && promotedObjectType === ECampaignPromotedType.landingPage) {
			formMethods.setValue('destination', account.websiteLink ?? '');
		}
	}, [promotedObjectType]);

	const handleMetaValueChange = (
		value: IOption['value'][] | IOption[],
		generateByAI?: boolean,
	) => {
		if (typeof value[0] === 'string') return;

		formMethods.setValue(
			'promotedObject.type',
			generateByAI
				? ECampaignPromotedType.landingPage
				: ECampaignPromotedType.catalogs,
		);

		formMethods.setValue('destination', value[0]?.url ?? '');
	};

	useEffect(() => {
		if (!dirtyPlacements && isSubmitting) {
			onTemplateGalleryOpen();
			setIsSubmitting(false);
		}
	}, [dirtyPlacements, isSubmitting]);

	const moveToNextStep = () => {
		setOpenSections([false, true, false, false]);
		completeStep(0);
		setTimeout(() => sectionEndRef.current?.scrollIntoView(), 300);
	};

	const handleRegenerateDesignDirections = () => {
		moveToNextStep();
		onRegenerateDesignDirections();
	};

	const handleFocusOnFirstError = useCallback(() => {
		const errorFields = Object.keys(formMethods.formState.errors);
		if (errorFields.length > 0) {
			formMethods.setFocus(errorFields[0] as any);

			if (errorFields[0] === 'promotedObject' && promotedObjectRef.current) {
				promotedObjectRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			}
		}
	}, [formMethods]);

	const submitForm = async () => {
		const isFormValid = await formMethods.trigger();
		if (!isFormValid) {
			handleFocusOnFirstError();
			return;
		}

		moveToNextStep();
		if (dirtyPlacements) {
			setIsSubmitting(true);
			return;
		}
		if (
			!designDirections?.length ||
			designDirections.filter((designDirection) => designDirection.locked)
				.length < 3
		) {
			if (selectedTemplate) {
				await onCampaignSubmit();
			} else {
				onTemplateGalleryOpen();
			}
		}
	};

	const {
		data: products,
		refetch: refetchProducts,
		isLoading: isProductsLoading,
	} = useFetch<ICatalog[]>(() => getAccountConfigOptions('catalogs'));

	// const handleCreateProduct = async (payload: ICatalogForm) => {
	// 	try {
	// 		const createdCatalog = await createAccountConfigOptions(
	// 			'catalogs',
	// 			payload,
	// 		);
	// 		refetchProducts();
	// 		formMethods.setValue('promotedObject.value', [
	// 			{
	// 				value: createdCatalog._id,
	// 				label: payload.name,
	// 			},
	// 		]);
	// 		formMethods.setValue('destination', payload.url);
	// 	} catch (error: any) {
	// 		toastError(error);
	// 	}
	// };
	// const handleCreateUpdateProduct = async (payload: ICatalogForm) => {
	// 	try {
	// 		if (!payload.id) {
	// 			await createAccountConfigOptions('catalogs', payload);
	// 		} else {
	// 			await updateAccountConfigOptions('catalogs', payload);
	// 		}
	// 		refetch();
	// 	} catch (error) {
	// 		toastError(error);
	// 	}
	// };
	const handleCreateNewMeta = (val: string) => {
		const defaultInitialValues = {
			kind: 'service',
			name: val,
			url: '',
			details: {},
			category: undefined,
			tags: [],
		};

		switch (promotedObjectType) {
			case ECampaignPromotedType.catalogs:
				formProductToggle.onOpen(defaultInitialValues);
				break;
			default:
				break;
		}
	};

	const getMetaOptions = () => {
		if (promotedObjectType === ECampaignPromotedType.catalogs) {
			const allCatalogs = [
				...scrappedCatalogs.map((catalog) => ({
					...catalog,
					image: catalog.image_url,
					label: catalog.name,
					value: catalog.id ?? catalog.catalogId,
					tags: catalog.tags,
				})),
				...(products || []).map((product) => ({
					...product,
					label: product.name,
					value: product.id,
				})),
			];

			const uniqueCatalogs = Array.from(
				new Map(allCatalogs.map((item) => [item.value, item])).values(),
			);

			const sortedCatalogs = uniqueCatalogs.sort((a, b) =>
				a.label.localeCompare(b.label),
			);

			return sortedCatalogs;
		}
	};

	const handleCreateToneOption = async (name: string) => {
		if (!name.trim()) {
			formToneToggle.onOpen();
			return;
		}

		await handleCreateTone({ name });
	};

	const handleCreateTone = async (payload: IAccountConfigOption) => {
		try {
			const newTone = await createTone(payload);
			formMethods.setValue('tone', [...(watchedTones || []), newTone.id]);
		} catch (error: any) {
			toastError(error);
		}
	};

	const handleCreateAudienceOption = async (name: string) => {
		if (!name.trim()) {
			formAudienceToggle.onOpen();
			return;
		}

		await handleCreateAudience({ name });
	};

	const handleCreateAudience = async (payload: IAccountConfigOption) => {
		try {
			const newAudience = await createAudience(payload);
			formMethods.setValue('audience', [
				...(watchedAudience || []),
				newAudience.id,
			]);
		} catch (error: any) {
			toastError(error);
		}
	};

	const isMetaOptionsLoading = isProductsLoading;
	const CAMPAIGN_META_OPTIONS: IOption[] | undefined = useMemo(
		() => getMetaOptions(),
		[isMetaOptionsLoading, promotedObjectType, scrappedCatalogs],
	);

	useEffect(() => {
		CAMPAIGN_META_OPTIONS?.length && onMetaOptionsChange(CAMPAIGN_META_OPTIONS);
	}, [CAMPAIGN_META_OPTIONS]);

	const queryStatus = searchParams.get('status');

	useEffect(() => {
		formMethods.setValue('budget.total', '0', { shouldDirty: false });

		return () => {
			setSelectedTemplate(null);
			setSelectedTemplateIds([]);
		};
	}, []);

	useEffect(() => {
		const checkAndOpenAccountSettingsModal = () => {
			const pendingSettings = pendingAccountSettings();
			const accountSettingsKeys = Object.keys(accountSettingsFields || {});
			const hasPendingSettings = accountSettingsKeys.some((key) =>
				pendingSettings.includes(key),
			);
			const hasMissingAccountSettings = accountSettingsKeys.some(
				(key) =>
					account &&
					(!account[key as keyof typeof account] ||
						!account[key as keyof typeof account]?.length),
			);
			const shouldOpenModal = hasPendingSettings && hasMissingAccountSettings;

			if (shouldOpenModal) {
				setIsAccountSettingsModalOpen(true);
			}
		};

		if (scrapeDataCalled) {
			checkAndOpenAccountSettingsModal();
		}
	}, [accountSettingsFields, account, scrapeDataCalled]);

	useEffect(() => {
		if (
			campaign &&
			campaign.promotedObject &&
			!isMetaOptionsLoading &&
			!isPromotedObjectLoaded &&
			CAMPAIGN_META_OPTIONS?.length
		) {
			const currentDestination = formMethods.getValues('destination');

			if (currentDestination) {
				const { promotedObject } = transformCampaignToForm(
					campaign,
					CAMPAIGN_META_OPTIONS,
				);
				if (promotedObject.type === promotedObjectType) {
					setIsPromotedObjectLoaded(true);
					formMethods.setValue('promotedObject.value', promotedObject.value);
					formMethods.setValue('destination', destination);
				}
			}
		}
	}, [campaign?.id, CAMPAIGN_META_OPTIONS]);

	useEffect(() => {
		if (
			campaign &&
			campaign.promotedObject &&
			!isMetaOptionsLoading &&
			!isCampaignLoaded
		) {
			setIsCampaignLoaded(true);
			const {
				title,
				description,
				promotedObject,
				destination,
				audience,
				tone,
				promotion,
				channels,
				budget,
				placements,
			} = transformCampaignToForm(campaign, CAMPAIGN_META_OPTIONS ?? []);

			formMethods.reset({
				title,
				description,
				promotedObject,
				destination,
				audience,
				tone,
				promotion,
				channels,
				budget,
				placements,
			});
		}
	}, [campaign, queryStatus, isMetaOptionsLoading]);

	const debouncedHandleFieldChanges = useCallback(
		debounce(() => {
			handleFieldChanges();
		}, 300),
		[],
	);

	useEffect(() => {
		const isOldCampaignLoaded = Boolean(campaign && isCampaignLoaded);
		const shouldSaveDelta = isNewCampaign || isOldCampaignLoaded;
		if (!shouldSaveDelta || !group || (isNewCampaign && !isFormDirty)) return;
		debouncedHandleFieldChanges();
	}, [
		watchedTitle,
		watchedDescription,
		promotedObjectType,
		watchedPromotedObjectValue,
		watchedDestination,
		watchedAudience,
		watchedTones,
		watchedPromotion,
		watchedGoals?.length,
		group,
		debouncedHandleFieldChanges,
		watchedPlacements,
	]);

	const handleFieldChanges = async () => {
		autosave.onFieldsChange({ ...formMethods.getValues(), group });
	};

	const setFieldValue = (field: any, value: string) => {
		formMethods.setValue(field, value, { shouldDirty: true });
		debouncedHandleFieldChanges();
	};

	const handleTitleTabKeyPress = (e: any) => {
		if (e.key === 'Tab') {
			e.preventDefault();
			formMethods.setFocus('description');
		}
	};

	const handleScrappedCatalogs = async (callback: string) => {
		try {
			const response = await processCallback(callback);
			if (response.catalogs?.length) {
				setScrappedCatalogs(response.catalogs);
				setValue('promotedObject.type', ECampaignPromotedType.catalogs, {
					shouldDirty: false,
				});

				// refetchProducts();
				const catalogId = response.catalogs[0].id;
				setValue('promotedObject.value', [
					{
						value: catalogId,
						label: response.catalogs[0].name,
						url: response.catalogs[0].image_url,
					},
				]);
				setValue('promotedObject.ids', [catalogId], { shouldDirty: true });
			}
		} catch (error) {
			console.error('Scraping error', error);
		} finally {
			setScrappedCatalogsLoading(false);
		}
	};

	const handleScrappedTonesAndAudiences = async (callback: string) => {
		const response = await processCallback(callback);
		response?.audiences &&
			setValue('audience', response.audiences, { shouldDirty: true });
		response?.tones && setValue('tone', response.tones, { shouldDirty: true });
		setLoadingInputConfig({ ['Tone']: false, ['Audience']: false });
		setIsTonesAndAudienceLoading(false);
	};

	const handleStartScrapping = () => {
		setScrappedDataLoading(true);
		setScrappedCatalogsLoading(true);
		setLoadingInputConfig({ ['Tone']: true, ['Audience']: true });
		setIsFilled(false);
	};

	const handleScrapedData = async (data: IScrapeDataOutput) => {
		setScrapeDataCalled(true);
		const {
			deduceTonesAudiencesExecution,
			catalogsExecution,
			brand,
			keywords,
			title,
		} = data;

		setPendingInterpretations({
			url_industry: brand.industry,
			url_keywords: keywords,
			url_source: data.url,
		});
		setTempUrlSource(data.url);
		const {
			url: websiteLink,
			name,
			description,
			logo,
			font,
			industry,
			keywords: brandKeywords,
		} = brand;
		const updatedAccountSettingsFields: Partial<IAccountSettingsForm> = {};
		if (data.url) {
			setValue('destination', data.url, { shouldDirty: true });
		}
		if (websiteLink && !account?.websiteLink?.length) {
			updatedAccountSettingsFields.websiteLink = websiteLink;
		}
		if (name && !account?.name?.length) {
			updatedAccountSettingsFields.name = name;
		}
		if (description && !account?.description?.length) {
			updatedAccountSettingsFields.description = description;
		}
		if (logo && !account?.logo?.length) {
			updatedAccountSettingsFields.logo = logo;
		}
		if (font && !account?.font?.length) {
			updatedAccountSettingsFields.brandIdentity = { fontType: font };
		}
		if (industry && !account?.industry) {
			updatedAccountSettingsFields.industry = industry;
		}
		if (keywords && !account?.keywords?.length) {
			updatedAccountSettingsFields.keywords = keywords;
		}
		if (data.url) {
			setIsFilled(true);
		} else {
			setIsFilled(false);
		}
		setAccountSettingsFields(updatedAccountSettingsFields);
		if (deduceTonesAudiencesExecution) {
			handleScrappedTonesAndAudiences(deduceTonesAudiencesExecution);
		} else {
			setLoadingInputConfig({ Tone: false, Audience: false });
		}
		if (catalogsExecution) {
			handleScrappedCatalogs(catalogsExecution);
		} else {
			setScrappedCatalogsLoading(false);
		}
		if (title) {
			setValue('title', title, { shouldDirty: true });
		}
		if (description) {
			setValue('description', description, { shouldDirty: true });
		}
	};

	useEffect(() => {
		if (!pendingInterpretations || !campaignId || campaignId === 'new') return;

		createOrUpdateCampaign(
			{ interpretations: pendingInterpretations },
			campaignId,
		)
			.then((updated) => {
				setCampaign(updated);
				setPendingInterpretations(null);
			})
			.catch((error) => {
				console.error('Failed to patch interpretations:', error);
			});
	}, [campaignId, pendingInterpretations, setCampaign]);

	const handleAccountFormSubmit = async (
		formData: Partial<IAccountSettingsForm>,
	) => {
		try {
			const data = await updateCompanyAccount(formData);
			setIsAccountSettingsModalOpen(false);
			toastSuccess('Your changes have been saved');
			setAccount(data);
		} catch (error: any) {
			toastError(error);
		}
	};

	const selectedPromotedObject: IOption | undefined = useMemo(() => {
		return CAMPAIGN_META_OPTIONS?.find(
			(option) => option.value === watchedPromotedObjectValue?.[0]?.value,
		);
	}, [CAMPAIGN_META_OPTIONS, watchedPromotedObjectValue]);

	useEffect(() => {
		if (campaign?.interpretations?.url_source) {
			setIsFilled(true);
		} else {
			setIsFilled(false);
		}
	}, [campaign?.interpretations?.url_source]);

	const handleScrapingError = () => {
		setLoadingInputConfig({
			...inputConfig,
			Tone: false,
			Audience: false,
		});
		setScrappedCatalogsLoading(false);
	};

	useEffect(() => {
		const currentDestination = formMethods.watch('destination');
	}, [formMethods.watch('destination')]);

	useEffect(() => {
		if (campaign?.interpretations?.url_source) {
			setTempUrlSource(null);
		}
	}, [campaign?.interpretations?.url_source]);

	return (
		<Container w="full" maxW="full" pb={5} m={0}>
			<FormProvider {...formMethods}>
				<form>
					<Flex direction="column" gap={10}>
						{isFilled ? (
							<CustomContainer withGradientBorder>
								<Flex direction="row" alignItems="center" gap={4} w="100%">
									<CheckIcon color="green.500" boxSize={6} />
									<Heading color="gray.900" fontSize="16px">
										We got below information from:
									</Heading>
									<Text flex="12">
										{tempUrlSource || campaign?.interpretations?.url_source}
									</Text>
									<Spacer />
									<Button
										variant="outline"
										color="#F7480B"
										borderColor="#F7480B"
										fontSize="14px"
										fontWeight="semibold"
										_hover={{ bg: 'rgba(247, 72, 11, 0.2)' }}
										leftIcon={<EditIcon2 />}
										onClick={() => setIsFilled(false)}
									>
										Edit
									</Button>
								</Flex>
							</CustomContainer>
						) : (
							<CustomContainer withGradientBorder>
								<Heading color="gray.900">
									Let us fill this form for you
								</Heading>
								<Text>
									We will obtain the information from the URL that you provide
									below
								</Text>
								<Box mt={3}>
									<DomainForm
										prevDomain={campaign?.interpretations?.url_source}
										onDataLoaded={handleScrapedData}
										setIsDataLoading={setScrappedDataLoading}
										isDataLoading={scrappedDataLoading}
										setIsExtraDataLoading={setLoadingInputConfig}
										onStartScrapping={handleStartScrapping}
										buttonText="Fill the form"
										isCampaign={true}
										onScrapingError={handleScrapingError}
									/>
								</Box>
							</CustomContainer>
						)}
						{selectedTemplate && <SelectedTemplatePreview />}
						<StringInputHook
							name="title"
							label="Campaign name"
							formLabelProps={{ fontSize: '16px' }}
							requirementsLabel="This is the name that you will use to identify this later.  e.g: Christmas campaign"
							placeholder="Enter campaign name"
							requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
							inputProps={{
								onChange: debouncedHandleFieldChanges,
								onKeyDown: (e: any) => handleTitleTabKeyPress(e),
							}}
							direction="row"
							required
							isLoading={scrappedDataLoading}
						/>
						<TextareaInputHook
							name="description"
							label="Describe your campaign"
							formLabelProps={{ fontSize: '16px' }}
							requirementsLabel="Describe what you are promoting, to whom, when, and what you want to achieve. The more you put here, the better the results."
							placeholder="What are you promoting and why? To whom? When? What are you trying to achieve? The more detail the better."
							textareaProps={{ onChange: debouncedHandleFieldChanges }}
							requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
							withAssistant
							setFieldValue={setFieldValue}
							required
							direction="row"
							isLoading={scrappedDataLoading}
						/>
						<Box ref={promotedObjectRef} />
						<MultiSelectCreatProducteableInputHook
							name="promotedObject.value"
							label="Do you have an image for what you are promoting?"
							requirementsLabel="Select or create a product or a service from your catalog"
							formLabelProps={{ fontSize: '16px' }}
							requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
							isLoading={isMetaOptionsLoading || scrappedCatalogsLoading}
							placeholder="Select/search"
							options={CAMPAIGN_META_OPTIONS ?? []}
							onValueChangeCallback={handleMetaValueChange}
							onCreateOption={handleCreateNewMeta}
							isMulti={false}
							promotedObjectType={promotedObjectType}
							valueAsObject
							isClearable
							required
							direction="row"
						/>
						{selectedPromotedObject && !scrappedCatalogsLoading && (
							<HStack gap={20} mt={-7}>
								<HStack flex={1}></HStack>
								<HStack
									alignSelf="flex-end"
									flex={1}
									spacing={3}
									cursor="pointer"
									onClick={() => {
										formProductToggle.onOpen({
											...selectedPromotedObject,
										});
									}}
								>
									<Image
										key={selectedPromotedObject.image}
										h="40px"
										w="40px"
										borderRadius="lg"
										objectFit="cover"
										src={selectedPromotedObject.image}
										fallbackSrc={IMAGE_PLACEHOLDER}
									/>
									<Text fontWeight="medium">
										{selectedPromotedObject.label}
									</Text>
									<Spacer />
									<Button
										variant="outline"
										height="5px"
										width="70px"
										color="#F7480B"
										borderColor="#F7480B"
										fontSize="14px"
										fontWeight="semibold"
										_hover={{ bg: 'rgba(247, 72, 11, 0.2)' }}
										leftIcon={<EditIcon2 />}
										onClick={() =>
											formProductToggle.onOpen(selectedPromotedObject)
										}
									>
										Edit
									</Button>
								</HStack>
							</HStack>
						)}
						<Flex direction="column">
							<MultiSelectCreateableInputHook
								name="audience"
								label="Who are your customers?"
								formLabelProps={{ fontSize: '16px' }}
								requirementsLabel="Select or create the characteristics which describe your customers"
								requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
								placeholder="Select/search"
								options={inputConfig['Audience'] ?? []}
								isLoading={
									loadingInputs['Audience'] || isTonesAndAudienceLoading
								}
								onCreateOption={handleCreateAudienceOption}
								isMulti
								required
								direction="row"
							/>
							{!loadingInputs['Audience'] && (
								<Flex alignSelf="flex-end">
									<MultiSelectValuesHook
										name="audience"
										title="Target Audience"
										options={inputConfig['Audience'] ?? []}
									/>
								</Flex>
							)}
						</Flex>
						<Flex direction="column">
							<MultiSelectCreateableInputHook
								name="tone"
								label="What tone of voice do you want to use?"
								formLabelProps={{ fontSize: '16px' }}
								requirementsLabel="Select or create the characteristics which describe how to communicate with your customers"
								requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
								placeholder="Select/search"
								options={inputConfig['Tone'] ?? []}
								isLoading={loadingInputs['Tone'] || isTonesAndAudienceLoading}
								onCreateOption={handleCreateToneOption}
								isMulti
								required
								direction="row"
							/>
							{!loadingInputs['Tone'] && (
								<Flex alignSelf="flex-end" maxW="50%">
									<MultiSelectValuesHook
										name="tone"
										options={inputConfig['Tone'] ?? []}
									/>
								</Flex>
							)}
						</Flex>
						<Box>
							<PromoCodesInputHook
								name="promotion"
								label="Will you offer a promotional code? (Optional)"
								requirementsLabel="Please enter a promotional discount code your customers can use"
								requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
								formLabelProps={{ mb: 0, fontSize: '16px' }}
								valuesContainerProps={{ mt: 0 }}
								direction="row"
								withRadioOptions={true}
							/>
						</Box>
						<MarketingChannelsForm
							availableChannels={availableChannels}
							availableChannelGroups={availableChannelGroups || []}
							requirementsLabel="Select all the channels where you want to promote your business and we will generate everything for that specific channel."
							requirementsLabelStyle={{ color: '#718096', fontSize: '14px' }}
							selectedPlacements={selectedPlacements}
							onChannelChange={handleChannelChange}
							onPlacementChange={handlePlacementChange}
						/>
						<Box display="none" textAlign="center">
							<Button
								id="campaign-config-form-submit"
								colorScheme="secondary"
								fontWeight="medium"
								onClick={submitForm}
							></Button>
						</Box>
						<Box alignSelf="flex-end">
							<Button
								variant="orangeSolid"
								type="button"
								isLoading={isGenerating || scrappedCatalogsLoading}
								id="campaign-config-form-submit-with-lock-modal"
								onClick={async () => {
									setIsGenerating(true);
									try {
										const isValid = await formMethods.trigger();
										if (
											isValid &&
											designDirections?.length &&
											designDirections?.some((dd) => dd.status === 'GENERATED')
										) {
											if (campaignId) {
												await genrateCampaignCreatives(campaignId, '', true);
											}
											lockDDToggle.onOpen(
												designDirections.filter(
													(dd) => dd.status === 'GENERATED',
												),
											);
										} else {
											await submitForm();
											setSelectedTemplateIds([]);
										}
									} catch (error) {
										console.error(error);
										toastError(
											'An unexpected error occurred while generating designs.',
										);
									} finally {
										setIsGenerating(false);
									}
								}}
							>
								Generate Designs
							</Button>
						</Box>
					</Flex>
				</form>
			</FormProvider>
			<Box ref={sectionEndRef}></Box>
			<ProductFormModal
				isOpen={formProductToggle.isOpen}
				catalogKinds={catalogKinds}
				onClose={formProductToggle.onClose}
				onSubmit={async (payload) => {
					if (payload.id) {
						await updateAccountConfigOptions('catalogs', payload);
						formMethods.setValue('promotedObject.value', [
							{ value: payload.id, label: payload.name },
						]);
						formMethods.setValue('destination', payload.url);
						refetchProducts();
					} else {
						const createdCatalog = await createAccountConfigOptions(
							'catalogs',
							payload,
						);
						formMethods.setValue('promotedObject.value', [
							{
								value: createdCatalog._id,
								label: payload.name,
							},
						]);
						formMethods.setValue('destination', payload.url);
					}
					refetchProducts();
				}}
				title="What are you promoting?"
				initialValues={formProductToggle.payload}
			/>
			<ConfigFormModal
				isOpen={formToneToggle.isOpen}
				onClose={formToneToggle.onClose}
				onSubmit={handleCreateTone}
				initialValues={formToneToggle.payload}
				title="Tone"
			/>
			<ConfigFormModal
				isOpen={formAudienceToggle.isOpen}
				onClose={formAudienceToggle.onClose}
				onSubmit={handleCreateAudience}
				initialValues={formAudienceToggle.payload}
				title="Audience"
			/>
			<DesignLockModal
				isOpen={lockDDToggle.isOpen}
				onCancel={handleCancel}
				onClose={lockDDToggle.onClose}
				onSubmit={handleRegenerateDesignDirections}
				designDirections={lockDDToggle.payload}
				setDesignDirections={setDesignDirections}
			/>
			{isSelectTemplateModalOpen && (
				<SelectTemplateModal
					isOpen={isSelectTemplateModalOpen}
					onClose={() => setSelectTemplateModalOpen(false)}
					handleRefreshDesignDirections={handleRefreshDesignDirections}
					source="campaignForm"
					onSubmit={onCampaignSubmit}
					industry={scrapedIndustry ?? account?.industry}
					keywords={scrapedKeywords ?? account?.keywords}
				/>
			)}
			<AccountSettingsModal
				isOpen={isAccountSettingsModalOpen}
				onClose={() => setIsAccountSettingsModalOpen(false)}
				onSubmit={handleAccountFormSubmit}
				accountSettingsFields={accountSettingsFields}
			/>
		</Container>
	);
};

export default CampaignForm;
